import React from 'react'

 const NewsItem = (props)=> {
   
  
    let {title,description,imageUrl,newsUrl,author,date,source} = props ;
    return (
      <div className='container my-3'>
        <div className="card" >
        <div style={{
          display:'flex',justifyContent:'felx-end',position:'absolute',right:'0'
        }}>
        <span className=" badge rounded-pill bg-danger">{source}</span>
        </div>
     <img src= {!imageUrl?"https://images.hindustantimes.com/img/2022/09/11/1600x900/440c464e-3105-11ed-a054-61a0e7dba4f0_1662884849830_1662884849830_1662884855203_1662884855203.jpg":imageUrl } className="card-img-top" alt="..."/>
     <div className="card-body">
    <h5 className="card-title">{title}...</h5>
    <p className="card-text">{!description?"Yes,Satya Loves Mithi more than his life.Because she is the girl who change his life,No ":description}...</p>
    <p className="card-text"><small class="text-muted">{!author?"Unknown":author} on {new Date(!date?"2022-08-26T10:48:47Z":date).toGMTString()} </small></p>
    <a href={newsUrl} target = "noreferrer" className="btn btn-sm btn-dark">Read More</a>
    </div>
   </div>
      </div>
    )
  
}

export default NewsItem
